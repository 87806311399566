<template>
  <div class="relative">
    <video ref="videoContainer" class="aspect-[1.56/1] bg-video mb-8"
           :class="videoClass"
           @click="initVideo()">
      <source :src="videoSrc">
    </video>
    <Transition name="slide-fade">
      <div  v-if="controlsMain" class="cs-absolute-container">
        <button v-for="butt in mainButtons" v-bind:key="butt"
                type="button" class="renoplast-video__btt-control"
                :class="`renoplast-video__btt-${butt}`"
                @click="playVideo(butt)"
        />
      </div>
    </Transition>
    <Transition name="slide-fade">
      <div v-if="isSystemActive" class="cs-absolute-container">
        <ul v-if="labelButtons.length > 0" class="cs-absolute-container">
          <li v-for="label in labelButtons" v-bind:key="label"
              class="renoplast-video__label-control"
              :class="`renoplast-video__label-${label}`"/>
        </ul>
        <div class="see-also cs-absolute-container">
          <ul class="breadcrumbs breadcrumbs-items">
            <li class="breadcrumbs-item"
                @click="contextMenu = true">
              <div class="breadcrumbs-label">
                <label class="block">
                  {{ currentTechnology }}
                </label>
                <spam class="block text-xs">
                  Zobacz również
                </spam>
              </div>
            </li>
            <li class="breadcrumbs-item">
              <div class="breadcrumbs-label">
                <label>
                  {{ currentSystem }}
                </label>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Transition>
    <Transition name="slide-down">
      <div v-if="isSystemActive && contextMenu" class="cs-absolute-container z-[100]">
        <div class="context-menu">
          <div class="context-menu--content">
            <h3 class="mt-2 mb-6 text-center text-xl font-bold uppercase">Filtruj i wybierz system zgodny z Twoimi oczekiwaniami</h3>
            <button class="context-menu--btt-close"
                    @click="contextMenu = false">
            </button>
            <ul class="flex items-center gap-8 mt-8 mb-12 px-4">
              <li>
                <div class="form-item relative">
                  <button id="dropdownHoverButton" class="text-white bg-emerald-700 hover:bg-emerald-800 focus:ring-4 focus:outline-none focus:ring-emerald-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-emerald-600 dark:hover:bg-emerald-700 dark:focus:ring-emerald-800" type="button"
                          data-dropdown-toggle="dropdownHover"
                          data-dropdown-trigger="hover"
                          @click="sortPriceState = !sortPriceState">
                    Sortuj
                    <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                    </svg>
                  </button>
                  <!-- Dropdown menu -->
                  <div id="dropdownHover" class="z-10 absolute transition-all bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                       :class="{ display: sortPriceState, hidden: !sortPriceState }">
                    <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownHoverButton">
                      <li v-for="sort in [
                      { key:'asc', label:'Rosnąco'},
                      { key:'desc', label:'Malejąco'}]" v-bind:key="sort">
                      <span class="block px-4 py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            @click="setSortPrice(sort.key)">
                        {{ sort.label }}
                      </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <label class="inline-flex items-center cursor-pointer">
                  <input type="checkbox" class="sr-only peer"
                         v-model="filterGutter"/>
                  <div class="relative w-11 h-6 bg-gray-300 rounded-full peer peer-focus:ring-4 peer-focus:ring-emerald-300 dark:peer-focus:ring-emerald-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-emerald-600"></div>
                  <span class="ms-3 text-xs font-medium text-gray-600 dark:text-gray-300">rynna</span>
                </label>
              </li>
              <li v-if="videoState === 'system-pedestal'">
                <label class="inline-flex items-center cursor-pointer">
                  <input type="checkbox" class="sr-only peer"
                         v-model="filterSlop"/>
                  <div class="relative w-11 h-6 bg-gray-300 rounded-full peer peer-focus:ring-4 peer-focus:ring-emerald-300 dark:peer-focus:ring-emerald-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-emerald-600"></div>
                  <span class="ms-3 text-xs font-medium text-gray-600 dark:text-gray-300">posadzka ze spadkiem</span>
                </label>
              </li>
              <li v-if="['system-pedestal', 'system-balcony'].includes(videoState)">
                <label class="inline-flex items-center cursor-pointer">
                  <input type="checkbox" class="sr-only peer"
                         v-model="filterCurve"/>
                  <div class="relative w-11 h-6 bg-gray-300 rounded-full peer peer-focus:ring-4 peer-focus:ring-emerald-300 dark:peer-focus:ring-emerald-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-emerald-600"></div>
                  <span class="ms-3 text-xs font-medium text-gray-600 dark:text-gray-300">dopasowan do łuku</span>
                </label>
              </li>
            </ul>
            <system-items :systemElements="systemElements" />
          </div>
        </div>
      </div>
    </Transition>
  </div>
  <ul v-if="isSystemActive && currentElements.length > 0" class="cs-elements-container py-12 bg-white flex gap-8 flex-col flex-wrap">
    <TransitionGroup name="fade-up">
      <li v-for="element in currentElements" v-bind:key="element" class="flex items-center justify-center h-32 bg-slate-50 rounded-md">
        <span>{{element}}</span>
      </li>
    </TransitionGroup>
  </ul>
</template>
<script setup>
import {computed, onMounted, ref} from "vue";
import SystemItems from "@/components/systemItems.vue";
const assetsVideoDir  = 'video/';
const videoStatus     = ref(false)
const videoState      = ref('init-loop')
const videoContainer  = ref(null);
const contextMenu     = ref(false);
const sortPrice       = ref('desc');
const sortPriceState  = ref(false);
const filterGutter    = ref(false);
const filterSlop      = ref(false);
const filterCurve     = ref(false);

const mainButtons = ['pedestal', 'stair', 'balcony', 'balcony2']
const defLabels = [
    {
      key:'pedestal',
      technology: 'Technologia Wentylowana',
      system: 'System W20',
      labels: ['pedestal', 'epdm', 'plinth', 'profile'],
      elements: [
        'element 1 - profile',
        'element 2 - narożnik',
        'element 3 - łącznik, etc...',
      ],
      systems: [
        {
          name: "W20",
          url: "w20",
          description: "Profil z maskowaniem czoła tarasu płytą 20 mm, na wspornikach tarasowych Renopad",
          price: 101,
          gutter: false,
          slop: true,
          curve: false,
        },
        {
          name: "W20+",
          url: "w20plus",
          description: "Profil z maskowaniem czoła tarasu płytą 20 mm, na wspornikach tarasowych Renopad z możliwością montażu rynny RK65.",
          price: 102,
          gutter: true,
          slop: true,
          curve: false,
        },
        {
          name: "W30",
          url: "w30",
          description: "Profil do posadzek wentylowanych z dużymi otworami odwadniającymi",
          price: 103,
          gutter: false,
          slop: false,
          curve: false,
        },
        {
          name: "W30R",
          url: "w30r",
          description: "Profil z dużymi otworami odwadniającymi z możliwością montażu aluminiowej rynny R50 lub RK65",
          price: 104,
          gutter: true,
          slop: false,
          curve: false,
        },
        {
          name: "W35",
          url: "w35",
          description: "Profil z do połączenia z hydroizolacją poprzez taśmę lub kołnierz z możliwością montażu aluminiowej rynny R50 lub RK35.",
          price: 105,
          gutter: true,
          slop: false,
          curve: true,
        },
        {
          name: "W60",
          url: "w60",
          description: "Profil do deski tarasowej lub płyt betonowych z możliwością montażu rynny R50/RK65.",
          price: 106,
          gutter: false,
          slop: false,
          curve: false,
        },
        {
          name: "D25",
          url: "d25",
          description: "Profil o szerokim zastosowaniu do posadzek wentylowanych lub klejonych",
          price: 107,
          gutter: false,
          slop: false,
          curve: false,
        },
        {
          name: "RENODRAIN",
          url: "renodrain",
          description: "Odwodnienie liniowe RENODRAIN S do tarasów naziemnych osadzonych na równo z trawnikiem.",
          price: 100,
          gutter: true,
          slop: false,
          curve: false,
        }
      ],
    },
  {
    key:'balcony',
    technology: 'Posadzki na zaprawie ceramicznych',
    system: 'System K100',
    labels: [],
    elements: [],
    systems: [
      {
        name: "K100",
        url: "k100",
        description: "Profil z wydłużonym kapinosem",
        price: 100,
        gutter: false,
        slop: false,
        curve: false,
      },
      {
        name: "K100R",
        url: "k100r",
        description: "Profil okapowy do płytek na kleju z możliwością montażu aluminiowej rynny R50",
        price: 101,
        gutter: true,
        slop: false,
        curve: false,
      },
      {
        name: "K301",
        url: "k301",
        description: "Profil okapowy do płytek na kleju",
        price: 101,
        gutter: false,
        slop: false,
        curve: false,
      },
      {
        name: "K102",
        url: "k102",
        description: "Profil do płytek na kleju z dedykowaną taśmą wzmacniającą CLEVER do połączenia z hydroizolacją",
        price: 103,
        gutter: false,
        slop: false,
        curve: false,
      },
      {
        name: "K30",
        url: "w30",
        description: "Profil chroniący czoło płytki z otworami odprowadzającymi wilgoć",
        price: 104,
        gutter: false,
        slop: false,
        curve: false,
      },
      {
        name: "K35",
        url: "w35",
        description: "Profil do połączenia z hydroizolacją poprzez taśmę z możliwością montażu aluminiowej rynny R50",
        price: 105,
        gutter: true,
        slop: false,
        curve: true,
      },
    ],
  },
  {
    key:'balcony2',
    technology: 'Posadzki na zaprawie żywicznej',
    system: 'System K10',
    labels: [],
    elements: [],
    systems: [
      {
        name: "K10",
        url: "k10",
        description: "Profil do cienkowarstwowej posadzki żywicznej.",
        price: 100,
        gutter: false,
        slop: false,
        curve: false,
      },
      {
        name: "K10R",
        url: "k10r",
        description: "Profil do cienkowarstwowej posadzki żywicznej z możliwością montażu aluminiowej rynny R50",
        price: 101,
        gutter: true,
        slop: false,
        curve: false,
      },
      {
        name: "K20",
        url: "k20",
        description: "Profil do posadzki żywicznej \"kamienny dywan\"",
        price: 102,
        gutter: false,
        slop: false,
        curve: false,
      },
      {
        name: "K20R",
        url: "k20r",
        description: "Profil do posadzki żywicznej \"kamienny dywan\" z możliwością montażu aluminiowej rynny R50",
        price: 102,
        gutter: true,
        slop: false,
        curve: false,
      },
    ],
  }
]
const initVideo = () => {
  const video = videoContainer.value;
  if ( typeof video !== "undefined" && !videoStatus.value) {
    videoStatus.value = !videoStatus.value
    switch (videoState.value) {
      case 'init-loop':
        video.addEventListener('ended', videoLoop)
        video.play()
        break;
    }
  }
}
const playVideo = (stage) => {
  const video = videoContainer.value;
  if ( typeof video !== "undefined") {
    video.removeEventListener('ended', videoLoop)
    video.pause()
    videoState.value = `system-${stage}`
    setTimeout( ()=> {
      video.addEventListener('ended', videoEnd)
      video.load();
      video.play()
      videoStatus.value = true
    }, 100)
  }
}
const videoSrc = computed(() => {
  return `/${assetsVideoDir}${videoState.value}.mp4`
})
const videoClass = computed(() => {
  return `bg-video--${videoState.value}`
})
const controlsMain = computed(() => {
  return videoState.value === 'init-loop' && videoStatus.value
})
const labelData = computed(() => {
  return defLabels.find( lab => lab.key === videoState.value.substr(7))
})
const labelButtons = computed(() => {
  return !videoStatus.value && typeof labelData.value !== "undefined"
      ? labelData.value.labels
      : []
})
const isSystemActive = computed(() => {
  return videoState.value !== 'init-loop' && !videoStatus.value
})
const currentTechnology = computed(() => {
  return typeof labelData.value !== "undefined"
      ? labelData.value.technology
      : ''
})
const currentSystem = computed(() => {
  return typeof labelData.value !== "undefined"
      ? labelData.value.system
      : ''
})
const currentElements = computed(() => {
  return typeof labelData.value !== "undefined"
      ? labelData.value.elements
      : []
})
const systemElements = computed(() => {
  let systems = typeof labelData.value !== "undefined"
      ? [...labelData.value.systems]
      : []
  if (filterGutter.value) {
    systems = [...systems.filter( sys => sys.gutter === true)]
  }
  if (filterSlop.value) {
    systems = [...systems.filter( sys => sys.slop === true)]
  }
  if (filterCurve.value) {
    systems = [...systems.filter( sys => sys.curve === true)]
  }
  systems = sortPrice.value === 'desc'
      ? [...systems.sort( (a, b) => parseInt(a.price) - parseInt(b.price))]
      : [...systems.sort( (a, b) => parseInt(b.price) - parseInt(a.price))]
  return systems
})
const videoLoop = () => {
  const video = videoContainer.value;
  if ( typeof video !== "undefined") {
    video.play()
  }
}
const setSortPrice = (sort) => {
  sortPrice.value       = sort
  sortPriceState.value  = false
}
const videoEnd = () => {
  videoStatus.value = false
}
onMounted(() => {
})
</script>
<style lang="scss">
.bg-video {
  background: no-repeat center top;
  background-size: contain;
  &--init-loop {
    background-image: url("@/assets/image/bg-video.jpg");
  }
}
.cs-absolute-container {
  @apply absolute left-0 top-0 right-0 bottom-0;
}
.context-menu {
  @apply mx-auto w-10/12 h-5/6 bg-white/[0.9] p-8 pr-8 rounded-b-2xl backdrop-blur-sm overflow-hidden;
  &--content {
    @apply h-full overflow-hidden;
  }
  &--btt-close {
    @apply absolute right-10 top-10 w-8 h-8 bg-center cursor-pointer;
    background-image: url("@/assets/image/gray-cross.svg");
  }
}
.breadcrumbs {
  &-items {
    @apply flex;
  }
  &-item {
    @apply block text-lg last:font-bold relative cursor-pointer;
    &:first-child {
      > div {
        @apply py-2;
      }
    }
    &:last-child {
      > div {
        @apply pl-16 bg-teal-600;
        &:after {
          @apply border-l-teal-600;
        }
      }
    }
  }
  &-label  {
    @apply text-white flex flex-col items-center py-4 px-8 bg-teal-500 after:block after:absolute after:w-0 after:h-0 after:top-1/2 after:left-full after:z-20 after:content-['_'] before:block before:absolute before:w-0 before:h-0 before:top-1/2 before:left-full before:content-["_"] before:z-20 ;
    &:after {
      border-top: 30px solid transparent; /* Go big on the size, and let overflow hide */
      border-bottom: 30px solid transparent;
      border-left: 30px solid rgb(20, 184, 166);
      margin-top: -30px;
    }
    &:before {
      border-top: 30px solid transparent;
      border-bottom: 30px solid transparent;
      border-left: 30px solid white;
      margin-top: -30px;
      margin-left: 2px;
    }
  }
}
.renoplast-video__label {
  &-control {
    @apply block absolute bg-center cursor-pointer z-10;
  }
  &-pedestal {
    width: 446px;
    height: 83px;
    background-image: url("@/assets/image/button/lab-pedestal.svg");
    top: 49%;
    left: 15%;
  }
  &-plinth {
    width: 415px;
    height: 83px;
    background-image: url("@/assets/image/button/lab-plinth.svg");
    top: 13%;
    left: 45%;
  }
  &-profile {
    width: 293px;
    height: 83px;
    background-image: url("@/assets/image/button/lab-profile.svg");
    top: 77%;
    left: 14%;
  }
  &-epdm {
    width: 415px;
    height: 83px;
    background-image: url("@/assets/image/button/lab-epdm.svg");
    top: 45%;
    left: 68%;
  }
}
.renoplast-video__btt {
  //$node: &;
  &-control {
    @apply block absolute bg-center cursor-pointer z-10;
  }
  &-stair {
    width: 290px;
    height: 83px;
    background-image: url("@/assets/image/button/bt-stair.svg");
    top: 66%;
    left: 72%;
  }
  &-pedestal {
    width: 289px;
    height: 83px;
    background-image: url("@/assets/image/button/bt-terace.svg");
    top: 69%;
    left: 43%;
  }
  &-balcony {
    width: 289px;
    height: 83px;
    background-image: url("@/assets/image/button/bt-terace2.svg");
    top: 33%;
    left: 69.5%;
  }
  &-balcony2 {
    width: 289px;
    height: 83px;
    background-image: url("@/assets/image/button/bt-terace.svg");
    top: 41%;
    left: 29.5%;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.slide-down-enter-active {
  transition: all 0.3s ease-out;
}

.slide-down-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.3, 0.5, 1);
}

.slide-down-enter-from,
.slide-down-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

.fade-up-enter-active {
  transition: all 0.3s ease-out;
}

.fade-up-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.3, 0.5, 1);
}

.fade-up-enter-from,
.sfade-up-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
