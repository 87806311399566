<template>
  <div class="container 2xl:max-w-screen-xl mx-auto relative mt-[26rem]">
    <video-player/>
  </div>
</template>
<script>
import VideoPlayer from "@/components/videoPlayer.vue";
export default {
  name: 'App',
  components: {VideoPlayer}
}
</script>
<style>
#app {
}
</style>
